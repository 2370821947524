import React from 'react';
import { event } from 'utils/logger';
import { useTranslation } from 'utils/i18next';
import { SendEvent } from 'utils/logging/types';
import { css, mq, styled } from 'utils/styles';
import { Flex, Section } from 'components/common';
import { FxTrends } from './FxTrends';
import { FxPodcasts } from './FxPodcasts';
import { FxVideo } from './FxVideo';

interface ResearchProps {
	sendEvent?: SendEvent;
}

const ResearchRightPane = styled.div(
	({ theme: { space, colors } }) => css`
		${mq({
			width: ['100%', '100%', '100%', '360px'],
			marginLeft: [0, 0, 0, space.xlarge],
			paddingLeft: [0, 0, 0, space.xlarge],
			borderLeft: ['none', 'none', 'none', `1px solid ${colors.grey3}`]
		})}
	`
);

export const Research = (props: ResearchProps) => {
	// todo: add events tracking
	const { sendEvent = event } = props;
	const { t } = useTranslation('research');

	return (
		<Section id="research-articles" data-nosnippet>
			<h2>{t('heading')}</h2>
			<p>{t('description')}</p>
			<Flex
				flex={1}
				flexDirection={['column', 'column', 'column', 'row']}
				marginTop="xxlarge"
			>
				<FxVideo display={['block', 'block', 'block', 'none']} />
				<FxTrends />
				<ResearchRightPane data-testid="research-styled-flex">
					<FxVideo display={['none', 'none', 'none', 'block']} />
					<FxPodcasts />
				</ResearchRightPane>
			</Flex>
		</Section>
	);
};
