module.exports = {
	// todo: get rid of unsafe-inline and unsafe-eval directives (it's tmp solution)
	csp: {
		directives: {
			'default-src': [
				"'self'",
				"'unsafe-inline'",
				"'unsafe-eval'",
				'*.hsbc.com',
				'*.eloqua.com/e/f2',
				'*.tiqcdn.com',
				'*.omtrdc.net',
				'*.fls.doubleclick.net',
				'hsbcbankgbm.demdex.net',
				'dpm.demdex.net',
				'collect.tealiumiq.com',
				'cm.everesttech.net',
				'www.googletagmanager.com',
				'ad.doubleclick.net',
				'td.doubleclick.net',
				'adservice.google.com',
				'cdn.heapanalytics.com',
				'edge.api.brightcove.com',
				'players.brightcove.net'
			],
			'img-src': [
				"'self'",
				'heapanalytics.com',
				'*.hsbc.com',
				'ad.doubleclick.net',
				'metrics.brightcove.com',
				'cf-images.eu-west-1.prod.boltdns.net'
			],
			'media-src': ['data:', "'self'", "'unsafe-inline'", "'unsafe-eval'", 'blob:'],
			'worker-src': ["'self'", 'blob:'],
			'font-src': ['data:', "'self'", "'unsafe-inline'", "'unsafe-eval'", '*.hsbc.com'],
			'connect-src': [
				"'self'",
				'*.brightcovecdn.com',
				'akamai.tiqcdn.com',
				'edge.api.brightcove.com',
				'manifest.prod.boltdns.net'
			]
		}
	},
	rateAlertPayloadMaxSize: 20 * 1024,
	hsbcEmailDomains: [
		'hsbcib.com',
		'hsbc.com',
		'hsbcgroup.com',
		'hsbc.com.ar',
		'hsbc.com.tr',
		'hsbc.com.au',
		'hsbc.com.cn',
		'hsbc.com.lk',
		'hsbc.com.hk',
		'hsbc.com.sg',
		'hsbc.com.my',
		'hsbc.com.mo',
		'hsbc.com.vn',
		'hsbc.com.bd',
		'hsbc.com.mx',
		'hsbc.com.tw',
		'hsbc.com.uy',
		'hsbc.com.ph',
		'za.hsbc.com',
		'us.hsbc.com',
		'kr.hsbc.com',
		'cl.hsbc.com',
		'hsbc.co.th',
		'hsbc.co.id',
		'hsbc.co.mu',
		'hsbc.co.in',
		'hsbc.co.jp',
		'hsbc.fr',
		'hsbc.bm',
		'noexternalmail.hsbc.com'
	]
};
