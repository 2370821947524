const config = require('../config');

export const isEmailDomainValid = email => {
	const emailParts = email.split('@');
	if (emailParts.length !== 2 || emailParts[0].trim() === '') {
		return false;
	}
	const domain = emailParts[1].toLowerCase();
	return config.hsbcEmailDomains.includes(domain);
};
